import React from "react"
import "../styles/styles.scss"
import Header from "./Header"

const Layout = ({ children }) => {
  return (
    <div className="layout-wrapper">
      <Header />
      <div className='child-wrapper'>{children}</div>
    </div>
  )
}

const ProjectLayout = ({ children }) => {
  return <section className="project">{children}</section>
}

const SectionLayout = ({ children }) => {
  return <section className="page">{children}</section>
}
export { Layout, ProjectLayout, SectionLayout }
