import React from "react"
import Blog from "./Journal"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Jerry Davidson web journal"
        />
        <title>Jerry Davidson Web Journal</title>
      </Helmet>
      <Blog headerText="Latest entry" previewLimit={1} />
    </>
  )
}
export default IndexPage
