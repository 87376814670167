import React from "react"
import { Layout } from "../components/Layout"
import "../styles/styles.scss"

const About = () => (
  <Layout>
    <div className="about-container">
      <section className="about-section">
        <p>
          I'm a <b>software engineer</b> and <b>tinkerer</b>. Explore my <b>journal</b> to discover
          my interests through my project writings.
        </p>
        <br></br>
        <p>
          Have any <b>questions</b> about my projects or related topics? Feel free to reach out at <b><a href='mailto:jerryddev@gmail.com'>jerryddev@gmail.com</a></b>
        </p>
      </section>
    </div>
  </Layout>
)

export default About
