import React from "react"
import { Layout} from "../components/Layout"
import BlogList from "../components/BlogList"
const Blog = props => {
  const {headerText, previewLimit} = props;


  return (
    <Layout>
      <div className="blog-container">
        <section className="blog-preview-container">
          <div className="blog-list-header">{headerText ? <h3>{headerText}</h3> : null}</div>
        <BlogList  previewLimit={previewLimit} />
        </section>
      </div>
    </Layout>
  )
}
export default Blog
