import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlogPreview from "./BlogPreview"

const BlogList = props => {
  const data = useStaticQuery(
    graphql`
      query BlogQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              sections: { elemMatch: { section: {} } }
              type: { eq: "blog" }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 80)
              frontmatter {
                title
                date
                slug
                image
                sections {
                  section {
                    image
                    text
                    readTime
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const {previewLimit} = props;
  console.log("previewLimit", previewLimit)

  const blogPreviews = (previewLimit
    ? data.allMarkdownRemark.edges.slice(0, previewLimit)
    :  data.allMarkdownRemark.edges
  ).map((blog, i) => {
    const { node } = blog
    const { frontmatter } = node
    const { date, title, slug, image } = frontmatter
    const { excerpt } = node
    const { readTime } = frontmatter.sections[0].section

    return (
      <BlogPreview
        key={i}
        previewData={{
          date: date,
          title: title,
          slug: slug,
          image: image,
          excerpt: excerpt,
          readTime: readTime,
        }}
      />
    )
  })

  return (
    <section className="bl-section">
      <div>{blogPreviews}</div>
    </section>
  )
}

export default BlogList
