import React from "react"
import { Link } from "gatsby"

const Header = () => {
  const siteLogo = "JD"
  return (
    <header>
      <div className="header-container">
        <nav className="header-nav">
          <Link to="/">
            <p className="site-title">{siteLogo}</p>
          </Link>
          <ul className="nav-bar">
            <Link
              activeClassName="underline"
              to="/Journal"
              rel="noopener noreferrer"
              partiallyActive={true}
            >
              <p className="nav-link">Journal</p>
            </Link>

            <Link
              activeClassName="underline"
              to="/About"
              rel="noopener noreferrer"
              partiallyActive={true}
            >
              <p className="nav-link">About</p>
            </Link>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
