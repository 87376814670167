import React from "react"
import CodeSnippet from "./CodeSnippet"
import InputList from "./InputList"

const BlogSection = props => {
  const { date, title, image } = props
  const sections = props.sections.map((section, i) => {
    const {
      readTime,
      image,
      text,
      language,
      snippet,
      header,
    } = section.section
    return (
      <section key={i} className="blog-section-container">
        {readTime ? (
          <p className=" read-time sub-text">{readTime + " min read"}</p>
        ) : null}
        {header ? (
          <h2 className="header blog-section-header">{header}</h2>
        ) : null}

        <p
          className="blog-text text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
        <div className="blog-image-container">
          {image ? <img alt="" src={image} /> : null}
        </div>
        {snippet && language ? (
          <CodeSnippet language={language} snippet={snippet} />
        ) : null}
      </section>
    )
  })
  return (
    <article>
      <h1 className="blog-title header">{title}</h1>
      <p className="sub-text blog-date">{date}</p>
      <div className="blog-image-container">
        {image ? (
          <div className="blog-image-container">
            <img alt="" src={image} />
          </div>
        ) : null}
      </div>
      {sections}
    </article>
  )
}

export default BlogSection
